const faqs = [
  {
    question: "Beneficios del seguro de viaje internacional",
    answer: (
      <div>
        <p>- Asistencia médica: La asistencia médica en Europa puede ser costosa, especialmente para los visitantes extranjeros. Un seguro de viaje te protegerá en caso de una enfermedad o lesión durante tu estadía en Europa, cubriendo los gastos médicos y hospitalarios necesarios. Además, en caso de una emergencia grave, el seguro de viaje puede cubrir la repatriación médica.</p><br/>
        <p>- Cancelación de viaje: A veces, surgen circunstancias imprevistas que te obligan a cancelar o interrumpir tu viaje a Europa. Ya sea una enfermedad repentina, problemas legales o situaciones climáticas extremas, el seguro de viaje te reembolsará los gastos no reembolsables, como boletos de avión, alojamiento y actividades programadas. Esta cobertura te permite planificar con mayor tranquilidad y minimiza el impacto financiero de una cancelación inesperada.</p><br/>
        <p>- Pérdida de equipaje y retrasos: Existe una posibilidad de que tu equipaje se pierda, sea robado o sufra retrasos durante un vuelo. Un seguro de viaje cubrirá los gastos relacionados con la pérdida o retraso del equipaje, como la compra de artículos esenciales y la reposición de pertenencias personales. Además, si experimentas retrasos significativos en tus vuelos, el seguro de viaje puede compensarte por los gastos adicionales incurridos, como alojamiento y comidas.</p><br/>
        <p>- Asistencia legal: En situaciones adversas, como un accidente automovilístico o un problema legal, el seguro de viaje te brinda asistencia legal y te protege de los costos legales involucrados. Contar con esta cobertura te permitirá afrontar situaciones inesperadas con mayor confianza y respaldo profesional.</p><br/>
    
      </div>
    ),
  },
  {
    question: "Consejos para elegir el mejor seguro de viaje a Europa",
    answer: (
      <div>
      <p>- Evalúa tus necesidades: Antes de seleccionar un seguro de viaje a Europa desde México, considera tus necesidades específicas. Piensa en la duración de tu viaje, los destinos que visitarás y las actividades que realizarás para asegurarte de obtener la cobertura adecuada.</p><br/>
      <p>-Comparación de planes: Investiga y compara diferentes planes de seguro de viaje para Europa. Presta atención a los detalles de la cobertura, los límites de indemnización y las exclusiones. Asegúrate de entender los términos y condiciones antes de tomar una decisión.</p><br/>
      <p>- Opiniones y recomendaciones: Lee opiniones de otros viajeros y busca recomendaciones sobre compañías de seguros confiables. Escuchar las experiencias de otras personas puede ayudarte a tomar una decisión informada y encontrar un seguro de viaje confiable y de calidad.</p><br/>
  
    </div>
    ),
  },
 
];

export default faqs;
